var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-section",
    { attrs: { "class-name": "projects", "section-title": _vm.pageTitle } },
    [
      _c(
        "ul",
        { staticClass: "projects__list" },
        _vm._l(_vm.projects, function (project) {
          return _c(
            "li",
            {
              key: project.id,
              staticClass: "project",
              on: {
                touchstart: function ($event) {
                  return _vm.activateProject(project.id)
                },
              },
            },
            [
              _c("figure", { staticClass: "project__figure" }, [
                _c("img", {
                  staticClass: "project__image",
                  attrs: {
                    alt: project.name,
                    src: _vm.images[`./projects/jpg/${project.image}`],
                  },
                }),
                _vm._v(" "),
                _c(
                  "figcaption",
                  {
                    class: [
                      "project__caption",
                      {
                        project__caption_active:
                          _vm.activeProjectId === project.id,
                      },
                    ],
                  },
                  [
                    _c("h2", { staticClass: "project__title" }, [
                      _vm._v(
                        "\n            " + _vm._s(project.name) + "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "project__text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(project.description) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }