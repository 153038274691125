var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-section",
    { attrs: { "class-name": "features", "section-title": _vm.pageTitle } },
    [
      _c(
        "ul",
        { staticClass: "features__list" },
        _vm._l(_vm.features, function (feature) {
          return _c(
            "li",
            { key: feature.id, staticClass: "features__list-item" },
            [
              _c("img", {
                staticClass: "features__icon",
                attrs: {
                  src: _vm.images[`./features/${feature.icon}`],
                  alt: feature.title,
                },
              }),
              _vm._v(" "),
              _c("h3", { staticClass: "features__subtitle" }, [
                _vm._v("\n        " + _vm._s(feature.title) + "\n      "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "features__text" }, [
                _vm._v("\n        " + _vm._s(feature.text) + "\n      "),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }