var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-section",
    { attrs: { "class-name": "contacts", "section-title": _vm.pageTitle } },
    [
      _c("div", { staticClass: "contacts__container" }, [
        _c("ul", { staticClass: "contacts__list" }, [
          _c("li", { staticClass: "contacts__list-item" }, [
            _c("span", { staticClass: "contacts__list-item-name" }, [
              _vm._v("Время работы:"),
            ]),
            _vm._v("\n        понедельник-воскресенье, круглосуточно\n      "),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "contacts__list-item" }, [
            _c("span", { staticClass: "contacts__list-item-name" }, [
              _vm._v("Телефон:"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "contacts__link",
                attrs: { href: "tel:+772323826" },
              },
              [_vm._v("\n          +7 (7232) 38-26\n        ")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "contacts__list-item" }, [
            _c("span", { staticClass: "contacts__list-item-name" }, [
              _vm._v("Почтовый адрес:"),
            ]),
            _vm._v(
              "\n        КССР, Усть-Каменогорская область, НПП им. Кирова, а/я 3826\n      "
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "contacts__list-item" }, [
            _c("span", { staticClass: "contacts__list-item-name" }, [
              _vm._v("Электронный адрес:"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "contacts__link",
                attrs: { href: "mailto:info@3826.su" },
              },
              [_vm._v("\n          info@3826.su\n        ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contacts__map" }, [
          _c("iframe", {
            attrs: {
              src: "https://yandex.ru/map-widget/v1/?um=constructor%3Ac693cf555911fe5611b81ea1ff36d19e6d76a0dbc7d81b67125754d71df0bc28&source=constructor",
              width: "100%",
              height: "400",
              frameborder: "0",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }