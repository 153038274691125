var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "section",
      class: _vm.className,
      attrs: { id: _vm.className },
    },
    [
      _vm.sectionTitle
        ? _c("h2", { class: `section__title ${_vm.className}__title` }, [
            _vm._v("\n    " + _vm._s(_vm.sectionTitle) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }